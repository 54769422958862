.dropdown-container {
  position: relative;
  width: 150px;
  font-family: 'Open Sans';
}

.upgrade-button {
  width: 100%;
  padding: 0.5rem 1rem;
  background-color: var(--purple);
  color: white;
  border-radius: 0.5rem;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: all 200ms ease;
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: 700;
}

.upgrade-button:hover {
  background-color: #410175;
}

.chevron-icon {
  width: 1rem;
  height: 1rem;
  transition: transform 0.2s ease;
}

.chevron-icon.open {
  transform: rotate(180deg);
}

.dropdown-menu {
  position: absolute;
  z-index: 10;
  width: 100%;
  margin-top: 0.5rem;
  background-color: #343333;
  border-radius: 0.5rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  font-family: 'Open Sans';
}

.dropdown-item {
  width: 100%;
  padding: 0.75rem 1rem;
  text-align: left;
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: white;
  font-family: 'Open Sans';
  font-size: 14px;
}

.dropdown-item.purple {
  /* color: #9333ea; */
  font-weight: 500;
}

.dropdown-item:hover {
  /* background-color: #f9fafb; */
  background-color: #201f1f;
}

.dropdown-item.purple:hover {
  /* background-color: #f5f3ff; */
  background-color: #201f1f;
}