* {
    --color-primary: #4f46e5;
    --color-primary-hover: #4338ca;
    --color-gray-50: #f9fafb;
    --color-gray-100: #f3f4f6;
    --color-gray-200: #e5e7eb;
    --color-gray-300: #d1d5db;
    --color-gray-400: #9ca3af;
    --color-gray-500: #6b7280;
    --color-gray-600: #4b5563;
    --color-gray-700: #374151;
    --color-gray-800: #1f2937;
    --color-gray-900: #111827;
}
.container {
    min-height: 100vh;
    background-color: var(--color-gray-50);
    font-family: 'Open Sans';
}

.header {
    background-color: white;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.headerContent {
    max-width: 80rem;
    margin: 0 auto;
    padding: 1rem;
}

.headerTitle {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--color-gray-900);
}

.main {
    max-width: 80rem;
    margin: 0 auto;
    padding: 2rem 1rem;
}

.statsGrid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1.5rem;
    margin-bottom: 2rem;
}

@media (min-width: 640px) {
    .statsGrid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 1024px) {
    .statsGrid {
        grid-template-columns: repeat(4, 1fr);
    }
}

.statCard {
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
    padding: 1.25rem;
}

.statContent {
    display: flex;
    align-items: center;
}

.statIcon {
    flex-shrink: 0;
    color: var(--color-gray-400);
}

.statInfo {
    margin-left: 1.25rem;
    flex: 1;
}

.statLabel {
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--color-gray-500);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.statValue {
    font-size: 1.125rem;
    font-weight: 600;
    color: var(--color-gray-900);
}

.searchContainer {
    margin: 2rem 0 1rem;
}

.searchWrapper {
    max-width: 28rem;
    position: relative;
}

.searchInput {
    width: 100%;
    padding: 0.5rem 0.75rem 0.5rem 2.5rem;
    border: 1px solid var(--color-gray-300);
    border-radius: 0.375rem;
    outline: none;
    transition: all 0.2s;
}

.searchInput:focus {
    border-color: var(--color-primary);
    box-shadow: 0 0 0 2px rgba(79, 70, 229, 0.2);
}

.searchIcon {
    position: absolute;
    left: 0.75rem;
    top: 50%;
    transform: translateY(-50%);
    color: var(--color-gray-400);
}

.table {
    width: 100%;
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.tableHeader {
    background-color: var(--color-gray-50);
}

.tableHeaderCell {
    padding: 0.75rem 1.5rem;
    text-align: left;
    font-size: 0.75rem;
    font-weight: 500;
    text-transform: uppercase;
    color: var(--color-gray-500);
    letter-spacing: 0.05em;
}

.tableBody {
    background-color: white;
}

.tableRow {
    border-bottom: 1px solid var(--color-gray-200);
}

.tableCell {
    padding: 1rem 1.5rem;
    white-space: nowrap;
}

.affiliateInfo {
    display: flex;
    align-items: center;
}

.affiliateEmail {
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--color-gray-900);
}

.affiliateId {
    font-size: 0.875rem;
    color: var(--color-gray-500);
}

.actionButton {
    color: var(--color-primary);
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    text-decoration: underline;
}

.actionButton:hover {
    color: var(--color-primary-hover);
}

.modal {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
}

.modalContent {
    background-color: white;
    border-radius: 0.5rem;
    max-width: 42rem;
    width: 100%;
    max-height: 90vh;
    overflow-y: auto;
}

.modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: start;
    padding: 1.5rem;
    border-bottom: 1px solid var(--color-gray-200);
}

.modalTitle {
    font-size: 1.25rem;
    font-weight: 600;
    color: var(--color-gray-900);
}

.closeButton {
    background: none;
    border: none;
    color: var(--color-gray-400);
    cursor: pointer;
    font-size: 1.5rem;
    padding: 0;
}

.closeButton:hover {
    color: var(--color-gray-500);
}

.modalSection {
    padding: 1.5rem;
    border-bottom: 1px solid var(--color-gray-200);
}

.modalSection:last-child {
    border-bottom: none;
}

.sectionTitle {
    font-size: 1rem;
    font-weight: 500;
    color: var(--color-gray-900);
    margin-bottom: 1rem;
}

.infoGrid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
}

@media (min-width: 640px) {
    .infoGrid {
        grid-template-columns: repeat(2, 1fr);
    }
}

.infoLabel {
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--color-gray-500);
}

.infoValue {
    font-size: 0.875rem;
    color: var(--color-gray-900);
    margin-top: 0.25rem;
}

.referredUsersList {
    list-style: none;
    padding: 0;
    margin: 0;
}

.referredUserItem {
    padding: 0.75rem 0;
    border-bottom: 1px solid var(--color-gray-200);
}

.referredUserItem:last-child {
    border-bottom: none;
}

.noEmail {
    font-style: italic;
    color: var(--color-gray-400);
}