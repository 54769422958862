.video-player {
    position: relative;
    width: 100%;
    background: var(--primary-black);
    border-radius: 12px;
    overflow: hidden;
}

.video-element {
    width: 100%;
    aspect-ratio: 16/9;
    background: #000;
    cursor: pointer;
}

.video-controls {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(transparent, rgba(0, 0, 0, 0.8));
    padding: 1rem;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.video-player:hover .video-controls {
    opacity: 1;
}

.progress-bar {
    position: relative;
    width: 100%;
    height: 4px;
    background: rgba(255, 255, 255, 0.2);
    cursor: pointer;
    margin-bottom: 0.75rem;
}

.progress-bar input[type="range"] {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.progress-fill {
    position: absolute;
    height: 100%;
    background: var(--primary-purple);
    transition: width 0.1s linear;
}

.controls-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--text-white);
}

.control-button {
    background: none;
    border: none;
    color: var(--text-white);
    cursor: pointer;
    padding: 0.5rem;
    border-radius: 4px;
    transition: all 0.2s ease;
}

.control-button:hover {
    background: rgba(255, 255, 255, 0.1);
}

.time-display {
    font-size: 0.875rem;
    margin: 0 1rem;
    font-family: "Open Sans";
}

.right-controls {
    display: flex;
    gap: 0.5rem;
}

/* Fullscreen styles */
.video-player:fullscreen {
    width: 100vw;
    height: 100vh;
}

.video-player:fullscreen .video-element {
    height: 100%;
}