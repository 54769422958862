.cta-modal {
  background: linear-gradient(145deg, #1a1a1a, #151618);
  border-radius: 24px;
  padding: 2.5rem;
  max-width: 400px;
  width: 90%;
  position: relative;
  border: 1px solid rgba(88, 0, 159, 0.3);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
  animation: modalEnter 0.3s ease-out;
}

@keyframes modalEnter {
  from {
    opacity: 0;
    transform: scale(0.95);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.modal-header {
  text-align: center;
  margin-bottom: 2rem;
}

.modal-header h2 {
  color: white;
  font-size: 2rem;
  font-weight: bold;
  margin: 1rem 0 0.5rem;
  background: linear-gradient(135deg, #fff 0%, #b49aff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.modal-header .subtitle {
  color: #cd7dff;
  font-size: .9rem;
  font-weight: 500;
}

.modal-header .subtitle2 {
  color: #ff3333;
  font-size: .9rem;
  font-weight: 500;
}

.sparkle-icon {
  color: #9F00FF;
  animation: sparkle 2s infinite;
}

@keyframes sparkle {

  0%,
  100% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(1.1);
    opacity: 0.8;
  }
}

.features {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
}

.feature {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: #fff;
  font-size: 1rem;
}

.feature svg {
  color: #9F00FF;
}

.pricing-highlight {
  background: rgba(159, 0, 255, 0.1);
  border: 1px solid rgba(159, 0, 255, 0.2);
  border-radius: 16px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.price {
  display: flex;
  align-items: baseline;
  gap: 0.5rem;
}

.price .original {
  color: #888;
  text-decoration: line-through;
  font-size: 1.25rem;
  position: relative; /* Needed for the pseudo-element */
}

.price .original::after {
  content: ''; /* No visible content */
  position: absolute; /* Allows precise positioning */
  left: 0;
  right: 0; /* Spans the full width of the text */
  top: 50%; /* Position at the middle of the text */
  height: 1.5px; /* Thickness of the line */
  background-color: #b31e1e; /* Color of the strikethrough */
  pointer-events: none; /* Ensures the pseudo-element doesn't interfere with clicks */
}

.price .current {
  color: white;
  font-size: 2.5rem;
  font-weight: bold;
}

.price .period {
  color: #888;
}

.save-badge {
  background: #9F00FF;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.875rem;
  font-weight: bold;
}

.modal-actions {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.upgrade-button {
  background: linear-gradient(135deg, #58009F, #9F00FF);
  color: white;
  padding: 1.25rem;
  border-radius: 12px;
  border: none;
  font-weight: bold;
  font-size: 1.125rem;
  cursor: pointer;
  transition: all 0.2s ease;
  animation: pulse 2s infinite;
  text-decoration: none;
  text-align: center;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.02);
  }

  100% {
    transform: scale(1);
  }
}

.upgrade-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 16px rgba(159, 0, 255, 0.3);
}

.continue-button {
  background: transparent;
  color: #888;
  padding: 1rem;
  border: none;
  font-size: 0.875rem;
  cursor: pointer;
  transition: color 0.2s ease;
}

.continue-button:hover {
  color: white;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal {
  background-color: #151618;
  border-radius: 16px;
  padding: 2rem;
  max-width: 500px;
  width: 90%;
  color: white;
}

.modal h2 {
  margin: 0 0 1rem 0;
  color: #58009F;
}

.modal p {
  margin-bottom: 2rem;
  color: #888;
  line-height: 1.5;
}

.modal-buttons {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
}

.modal-button {
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  border: none;
  font-weight: bold;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.modal-button:hover {
  opacity: 0.9;
}

.modal-button.secondary {
  background-color: #1f2026;
  color: white;
}

.modal-button.danger {
  background-color: #dc2626;
  color: white;
}

@media(max-width:444px) {
  .save-badge {
    position: absolute;
    top: -1rem;
    right: -2rem;
  }

  .original {
    font-size: .9em !important;
  }

  .cta-modal {
    padding-inline: 1.5em;
  }
}

@media(max-height:717px) {
  .cta-modal {
    padding-block: 1.5em;
  }
}

@media(max-height:683px) {
  .cta-modal {
    padding-block: .82em;
  }
}