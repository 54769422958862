/* EndlessMarquee.css */

.inner {
    position: relative;
    width: 100%;
    overflow: hidden;
    height: 6rem;
    /* Adjust as needed */

    /* Remove the existing clip-path */
    /* clip-path: inset(0 200px 0 200px); */

    /* Add mask-image for fading effect */
    mask-image:
        linear-gradient(to right, transparent, black 20px, black calc(100% - 50px), transparent);
    mask-size: 90% 100%;
    mask-repeat: no-repeat;
    mask-position: center;

    /* For better browser support, include WebKit prefix */
    -webkit-mask-image:
        linear-gradient(to right, transparent, black 20px, black calc(100% - 50px), transparent);
    -webkit-mask-size: 90% 100%;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: center;
}

/* This is the container that absolutely positions the sections side by side */
.wrapper {
    position: absolute;
    display: flex;
    gap: 1.2em;
}

/* Each <section> is a row with an animation that slides it from 0 to -100% */
section {
    display: flex;
    animation: swipe var(--speed) linear infinite backwards;
    gap: 1.2em;
}

section.reverse {
    animation-direction: reverse;
}

/* The repeating marquee animation:
     - we shift from 0% => -100% 
     so that by the time we’re at 100% of the animation,
     the entire section has slid off the left edge. */
@keyframes swipe {
    0% {
        transform: translate(0);
    }

    100% {
        transform: translate(-102%);
    }
}

/* Style each individual query bubble if you like */
.queryItem {
    display: flex;
    align-items: center;
    margin: 0 8px;
    border: 1px solid gray;
    border-radius: 10px;
    padding: 4px 12px;
    color: rgb(166, 172, 172);
    cursor: pointer;
    white-space: nowrap;
    gap: 1.2em;
    /* so the text doesn’t wrap to multiple lines */
    /* etc. */
}

@media(max-width:604px) {
    .inner {
        mask-size: 100% 100%;
    }
}