.pmodal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.pmodal {
    background-color: #151618;
    border-radius: 16px;
    padding: 2rem;
    max-width: 500px;
    width: 90%;
    color: white;
    position: relative;
}

.pmodal h2 {
    margin: 0 0 1rem 0;
    color: #58009F;
}

.pmodal p {
    margin-bottom: 2rem;
    color: #888;
    line-height: 1.5;
}

.pmodal-buttons {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
}

.pmodal-button {
    padding: 0.75rem 1.5rem;
    border-radius: 8px;
    border: none;
    font-weight: bold;
    cursor: pointer;
    transition: opacity 0.3s ease;
}

.pmodal-button:hover {
    opacity: 0.9;
}

.pmodal-button.secondary {
    background-color: #1f2026;
    color: white;
}

.pmodal-button.danger {
    background-color: #dc2626;
    color: white;
}

.promo-code-section {
    max-width: 400px;
    margin: 3rem auto 0;
    text-align: center;
}

.promo-code-toggle {
    background: none;
    border: none;
    color: #888;
    cursor: pointer;
    font-size: 0.9rem;
    text-decoration: underline;
    transition: color 0.2s ease;
}

.promo-code-toggle:hover {
    color: #58009F;
}

.promo-modal {
    background: #151618;
    border-radius: 16px;
    padding: 2rem;
    max-width: 400px;
    width: 90%;
    color: white;
    animation: modalEnter 0.3s ease-out;
}

.promo-modal h2 {
    color: #58009F;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: .75rem;
    text-align: center;
}

.promo-input {
    width: 100%;
    background: #1f2026;
    border: 1px solid #2a2b31;
    color: white;
    padding: 0.75rem 1rem;
    border-radius: 8px;
    font-size: 1rem;
    /* text-transform: uppercase; */
    margin-bottom: 1rem;
    transition: border-color 0.2s ease;
}

.promo-input:focus {
    outline: none;
    border-color: #58009F;
}

.pmodal-buttons {
    display: flex;
    gap: 1rem;
    margin-top: 1.5rem;
}

.pmodal-button {
    flex: 1;
    padding: 0.75rem;
    border-radius: 8px;
    border: none;
    font-weight: 600;
    cursor: pointer;
    transition: opacity 0.2s ease;
}

.pmodal-button:hover {
    opacity: 0.9;
}

.pmodal-button.primary {
    background: #58009F;
    color: white;
}

.pmodal-button.primary:disabled {
    background: #1f2026;
    cursor: not-allowed;
}

.pmodal-button.secondary {
    background: #1f2026;
    color: white;
}

.promo-message {
    font-size: 0.9rem;
    margin-top: 0.5rem;
}

.promo-message.success {
    color: #10b981;
}

.promo-message.error {
    color: #ef4444;
}

.pdesc {
    font-size: 14px;
    text-align: left;
    opacity: .6;
    margin-bottom: .75rem;
}

@keyframes modalEnter {
    from {
        opacity: 0;
        transform: scale(0.95);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}

.modal-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: none;
    border: none;
    color: #888;
    opacity: .65;
    cursor: pointer;
    /* padding: 0.5rem; */
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.4s ease;
}

.modal-close:hover {
    color: white;
    opacity: .8;
}