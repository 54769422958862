/* Article Layout */
.container {
    max-width: 64rem;
    margin: 0 auto;
    padding: 3rem 1rem;
    padding-bottom: 2rem;
    background-color: #f9fafb;
    font-family: "Open Sans";
}

@media (min-width: 640px) {
    .container {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}

/* Header */
.header {
    text-align: center;
    margin-bottom: 4rem;
}

.heroImage {
    margin-bottom: 2rem;
}

.heroImage img {
    width: 100%;
    height: 16rem;
    object-fit: cover;
    border-radius: 0.5rem;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.title {
    font-size: 2.25rem;
    font-weight: 700;
    color: #1a202c;
    margin-bottom: 1rem;
}

.subtitle {
    font-size: 1.25rem;
    color: #4a5568;
}

/* Metrics Grid */
.metricsGrid {
    display: grid;
    gap: 1.5rem;
    margin-bottom: 4rem;
}

@media (min-width: 768px) {
    .metricsGrid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 1024px) {
    .metricsGrid {
        grid-template-columns: repeat(4, 1fr);
    }
}

/* Content Sections */
.content {
    max-width: none;
}

.section {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
}

.sectionImage {
    margin-bottom: 2rem;
}

.sectionImage img {
    width: 100%;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.heading {
    font-size: 1.5rem;
    font-weight: 700;
    color: #1a202c;
    margin-bottom: 1rem;
}

.text {
    color: #4a5568;
    line-height: 1.75;
    margin-bottom: 1rem;
}

.list {
    list-style-type: disc;
    padding-left: 1.5rem;
    margin-bottom: 1rem;
}

.list li {
    margin-bottom: 0.5rem;
    color: #4a5568;
}

.footer {
    display: flex;
    justify-content: space-around;
    padding-block: 2rem;
    padding-inline: 4rem;
    background-color: rgb(231, 231, 231);
    font-family: "Open Sans";
}