/* Modern dark theme with purple accents */
:root {
    --primary-purple: #7300FF;
    --primary-black: #111111;
    --text-white: #FFFFFF;
    --text-gray: #A1A1AA;
}

.congratulations-container {
    background-color: var(--primary-black);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    color: var(--text-white);
}

.content-wrapper {
    max-width: 800px;
    width: 100%;
    text-align: center;
}

.ctitle {
    font-family: 'Open Sans';
    font-size: 3.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.11);
    background: linear-gradient(135deg, var(--primary-purple), #7300ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.csubtitle {
    font-size: 1.5rem;
    color: var(--text-gray);
    font-family: 'Open Sans';
    margin-bottom: 3rem;
    line-height: 1.5;
}

.video-container {
    width: 100%;
    max-width: 640px;
    margin: 0 auto 3rem;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 20px rgba(139, 92, 246, 0.2);
}

.video-container iframe {
    width: 100%;
    aspect-ratio: 16/9;
    border: none;
}

.buttons-container {
    display: flex;
    gap: 1.5rem;
    justify-content: center;
    flex-wrap: wrap;
}

.cbutton {
    padding: 1rem 2rem;
    border-radius: 8px;
    font-size: 1.125rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.primary-button {
    background: var(--primary-purple);
    color: var(--text-white);
    border: none;
}

.primary-button:hover {
    background: #7C3AED;
    transform: translateY(-2px);
}

.secondary-button {
    background: transparent;
    color: var(--primary-purple);
    border: 2px solid var(--primary-purple);
}

.secondary-button:hover {
    background: rgba(139, 92, 246, 0.1);
    transform: translateY(-2px);
}

@media(max-width:513px) {
    .ctitle {
        font-size: 2.5rem;
    }

    .csubtitle {
        font-size: .8rem;
    }
}

@media(max-width:448px) {
    .ctitle {
        font-size: 1.8rem;
    }
}