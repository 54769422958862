.chartContainer {
  background-color: var(--black);
  border-radius: 0.75rem;
  padding: 1.5rem;
}

.title {
  color: white;
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
}

.chartWrapper {
  height: 300px;
}

:global(.recharts-cartesian-grid-horizontal line),
:global(.recharts-cartesian-grid-vertical line) {
  stroke: var(--light-gray);
  opacity: 0.2;
}