* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    --purple: #58009F;
    --gray: #151618;
    --light-gray: #535353;
    --black: #000000;

}

body {
    background-color: #0a0a0a;
    --headerHeight: 80px;
    overflow-x: hidden;
    overflow-y: hidden;
}

.header {
    color: white;
    height: var(--headerHeight);
    display: flex;
    align-items: center;
    position: relative;
    font-size: 30px;
    font-family: "Open Sans";
    font-weight: 700;
}

.italic {
    padding-left: 23px;
    color: #ADADAD;
}

.bars {
    background-color: #151618;
    color: white;
    margin-left: 1em;
    padding: .6em;
    width: min-content;
    margin-bottom: 1em;
    display: flex;
    border-radius: 100px;
    cursor: pointer;
}

.bars1,
.bars2 {
    display: none;
}

.x {
    display: none;
}

.body {
    height: calc(100svh - var(--headerHeight));
    display: flex;
    padding-left: 10px;
    padding-bottom: 19px;
    padding-right: 11px;
    gap: 21px;
}

.header>* {
    position: absolute;
}

.logo {
    all: unset;
    cursor: pointer;
    margin-left: 23px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.welcome {
    left: 393px;
    font-weight: 34px;
}

.socials {
    right: 25px;
    display: flex;
    gap: .5em;
    height: 40px;
}

.dropdown {
    position: absolute;
    background-color: #151618;
    border: 1px solid #2f2f2f;
    border-radius: 7px;
    /* color: black; */
    padding-block: 1em;
    padding-inline: .7em;
    right: 0;
    margin-top: .1em;
    box-shadow: 0 0 0.5em 0 black;
    font-size: medium;
    width: 180px;
    display: flex;
    flex-direction: column;
    gap: .2em;
    /* align-items: center; */
    z-index: 199;
    width: 220px;
    font-weight: 500;
    font-family: "Open Sans";
}

.dropdown::before {
    content: '';
    position: absolute;
    right: .5em;
    top: -0.076em;
    width: .8em;
    height: .8em;
    background-color: #151618;
    /* border: 1px solid #2f2f2f; */
    z-index: -2000000;
    transform: rotate(45deg);
}

.dropdownItem {
    all: unset;
    display: flex;
    gap: .3em;
    /* border-bottom: 1px solid lightgray; */
    padding-bottom: .6em;
    cursor: pointer;
    align-items: center;
    gap: .4em;
}

/* .dropdownItem:last-of-type {
    padding-bottom: 0.3em;
} */

.h {
    opacity: .2;
    margin-bottom: .2em;
}

.dropdown.show {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: 200ms ease;
}

.dropdown.noShow {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-0.5em);
    transition: 200ms ease;
}

.dropdownItem:last-of-type {
    border-bottom: none;
}

.username {
    background: linear-gradient(to right, #9A3BE7, #3B6FDF);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
}

.leftBar {
    /* background-color: red; */
    height: 100%;
    width: 323px;
    display: flex;
    flex-direction: column;
    gap: 22px;
}

.card {
    background-color: #151618;
    padding-top: 23px;
    padding-left: 24px;
    padding-bottom: 22px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
}

.windows-os {
    ::-webkit-scrollbar {
        width: 10px;
        /* display: none; */
    }

    ::-webkit-scrollbar-track {
        background: transparent;
    }

    ::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 100vw;
        transition: all 200ms ease;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    ::-webkit-scrollbar-corner {
        display: none;
    }

    ::-webkit-scrollbar-track-piece {
        background-color: transparent;
    }

    ::-webkit-scrollbar-corner {
        display: none;
    }

    ::-webkit-resizer {
        display: none;
    }
}

.models {
    height: 342px;
    justify-content: space-between;
    flex-shrink: 0;
}

.card .text {
    color: white;
    font-size: 20px;
    font-family: "Open Sans";
    font-weight: 700;
}

.profile img {
    border-radius: 1000px;
    width: 60px;
}

.profile {
    display: flex;
    align-items: center;
    gap: 1em;
}

.profile .name {
    font-size: 18px;
    font-family: "Open Sans";
    font-weight: 700;
    color: white;
}

.profile .bio {
    font-size: 12px;
    font-family: "Open Sans";
    font-weight: 500;
    color: rgb(156, 163, 175);
}

.newAlpha {
    all: unset;
    font-family: "Open Sans";
    font-weight: 700;
    color: white;
    font-size: 16px;
    background-color: #1F2026;
    padding-block: 12.5px;
    padding-left: 1em;
    padding-right: 2em;
    border-radius: 10px;
    display: flex;
    align-items: center;
    gap: .55em;
    width: max-content;
    transition: all 200ms ease;
}

.plus {
    width: 17px;
    color: #64507B;
}

.history {
    gap: 1em;
    overflow: auto;
}

.middleBar {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.rightBar {
    width: 423px;
    height: 100%;
}

.chat {
    height: 100%;
    padding-right: 1.5em;
    display: flex;
    flex-direction: column;
}

.message {
    border-radius: 15px;
    width: fit-content;
    max-width: 610px;
    padding-inline: 1em;
    padding-block: .5em;
    font-size: 16px;
    font-family: "Open Sans";
    color: #dedede;
    margin-bottom: 1em;
    word-wrap: break-word;
}

.sending {
    background-color: #58009F;
    margin-left: auto;
}

.receiving {
    background-color: #1F2026;
}

.error {
    border: 1.5px solid rgba(249, 58, 55, 0.2);
    background-color: rgba(249, 58, 55, 0.08);
    box-sizing: border-box;
}

.top {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.icon {
    height: 47px;
    width: 47px;
    background-color: #1F2026;
    border-radius: 100svh;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 200ms ease;
    /* margin-right: 1.5em; */
}

.feedback-options {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin: 20px 0;
}

.radio-option {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}

.custom-reason {
    width: 100%;
    min-height: 100px;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid #ccc;
    margin: 12px 0;
    resize: vertical;
}

.modal-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.submit-button {
    padding: 8px 16px;
    border-radius: 6px;
    background-color: #5800FF;
    color: white;
    border: none;
    cursor: pointer;
}

.submit-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.links .icon:hover {
    background-color: #1b1c20;
    transform: scale(1.1);
}

.feedback-options {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
}

.reason-button {
    padding: 12px 20px;
    background-color: #f8f9fa;
    color: #f8f9fa;
    border: 2px solid #16161b;
    box-sizing: border-box;
    border-radius: 8px;
    text-align: left;
    font-size: 17px;
    padding: 1em;
    /* color: #212529; */
    background-color: #1F2026;
    transition: all 0.2s ease;
    cursor: pointer;
}

.reason-button.selected {
    background-color: #58009F;
}

.reason-button:hover {
    background-color: #58009F;
    transform: translateY(-1px);
}

.custom-reason-input {
    width: 100%;
    padding: 12px;
    margin-top: 20px;
    border: 1px solid #e9ecef;
    border-radius: 8px;
    font-size: 1rem;
    outline: none;
    transition: border-color 0.2s ease;
}

.custom-reason-input:focus {
    border-color: #5800FF;
}

.modal-content {
    max-width: 400px;
    width: 90%;
    padding: 24px;
}

.modal-body h2 {
    margin: 0 0 8px 0;
    font-size: 1.5rem;
}

.modal-body p {
    margin: 0;
    color: #6c757d;
}

.manage {
    color: rgb(71, 71, 71);
    text-decoration: underline;
    cursor: pointer;
}

.picContainer {
    margin-top: 1em;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.o {
    position: absolute;
    height: 418px;
    background: linear-gradient(#1f202600, #1F2026);
    top: 0;
    width: 320px;
}

.nameTitle {
    position: absolute;
    bottom: .3em;
    font-size: 50px;
    font-weight: 500;
    font-family: "Oswald";
    color: #FFF;
}

.nameDesc {
    position: absolute;
    bottom: 0;
    font-size: 14px;
    font-family: "Open Sans";
    color: #ADADAD;
}

.desc {
    font-size: 12px;
    font-family: "Open Sans";
    color: #ADADAD;
    /* margin-top: 2em; */
    line-height: 20px;
    padding-right: 1.5em;
    /* word-wrap: normal; */
    text-align: justify;
    display: flex;
    flex-direction: column;
    gap: .4em;
}

.profile.hoverable.selected .desc {
    padding-right: 0;
}

.desc2 {
    overflow: auto;
    max-height: calc(100svh - (150px + 23px + 27.5px + 1em + 2em + 47px + 1.5em + 21.5px + 410px));
}

.link {
    color: white;
    cursor: pointer;
}

.bottom {
    position: absolute;
    bottom: 1.5em;
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    width: 100%;
}

.links {
    display: flex;
    gap: 1.5em;
}

.convo {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin-top: 1em;
    /* gap: 1em; */
    overflow-y: auto;
    max-height: 100%;
}

.input {
    background-color: #0A0A0A;
    height: 65px;
    width: 100%;
    border-radius: 100px;
    font-size: 12px;
    font-family: "Open Sans";
    color: white;
    font-size: 14px;
    padding-left: 1.5em;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    z-index: 2999;
    border: none;
    outline: none;
    transition: all 200ms ease;
}


.input {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-block: 1.6em;
}

.bott {
    display: flex;
    justify-content: center;
    padding-top: 1em;
}

.sendContainer {
    background-color: #0A0A0A;
    height: 65px;
    display: flex;
    align-items: center;
    border-top-right-radius: 1000px;
    border-bottom-right-radius: 1000px;
    padding-left: 4px;
}

.send {
    all: unset;
    color: white;
    font-size: 14px;
    font-family: "Open Sans";
    font-weight: 700;
    background-color: #58009F;
    padding-block: 1em;
    padding-inline: 1.5em;
    border-radius: 100px;
    display: flex;
    align-items: center;
    gap: 1em;
    margin-right: 1em;
    transition: all 250ms ease;
    cursor: pointer;
    transition: all 200ms ease;
}

.send:hover {
    background-color: #430775;
}

.send:disabled {
    opacity: .6;
    cursor: not-allowed;
}

.closed {
    /* display: none; */
    width: 0 !important;
}

.picContainer {
    transition: opacity 300ms ease;
}

.closed .picContainer {
    /* display: none; */
    opacity: 0;
    z-index: -999;
}

.body:has(.middleBar.x) {
    padding-right: 0;
}

.meet {
    display: flex;
    align-items: center;
    gap: .5em;
}

.back {
    /* all: unset; */
    height: 42px;
    width: 42px;
    display: none;
    border: none;
}

.hoverable:hover {
    background-color: #adadad29;
    outline: 13px solid #adadad29;
    /* Gray outline */
    border-radius: 1px;
    cursor: pointer;
    width: 92.4%;
}

.history .hoverable:hover {
    background-color: #adadad29;
    outline: 13px solid #adadad29;
    /* Gray outline */
    border-radius: 1px;
    cursor: pointer;
    width: 82%;
}

.histContainer .desc,
.histContainer .bio {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: fit-content;
}

.histContainer .bio {
    max-width: 100%;
}

.aff-link {
    all: unset;
    font-weight: 700;
    color: #3B6FDF;
    /* color: #58009F; */
    text-decoration: underline;
    text-shadow: 0px 0px 7px #000000;
    cursor: pointer;
}

.newAlpha:hover {
    background-color: #58009F;
    cursor: pointer;
}

.newAlpha .plus {
    fill: white;
}

.back.back2 {
    display: none;
}

.fu {
    display: none;
    border: none;
    position: absolute;
    right: 1.8em;
}

.messageBott {
    position: absolute;
    display: flex;
    gap: .52em;
    opacity: 0;
    transition: all 340ms ease;
}

.message:hover .messageBott {
    opacity: .2;
}

.subscribe {
    background-color: #58009F;
    color: white;
    font-size: 16px;
    font-family: "Open Sans";
    font-weight: 700;
    padding-block: .5em;
    padding-inline: 1em;
    border-radius: 10px;
    text-decoration: none;
    cursor: pointer;
    transition: all 200ms ease;
    box-shadow: 0 5px 15px rgba(128, 0, 255, 0.2);
}


.subscribe:hover {
    background-color: #430775;
}

.sending .ops {
    display: none;
}

.ops {
    margin-top: .6em;
    display: flex;
    gap: .7em;
}

.i {
    color: #ffffff74;
    cursor: pointer;
}

.i:hover {
    background-color: #0000001e;
    outline: 5px solid #0000001e;
    border-radius: 2px;
}

.history .selected,
.menuButton.selected {
    background-color: #58009F;
    outline: 13px solid #58009F;
    border-radius: 1px;
    cursor: pointer;
    width: 80%;
}

.models .selected {
    background-color: #58009F;
    outline: 13px solid #58009F;
    border-radius: 1px;
    cursor: pointer;
    width: 91%;
}

.user {
    cursor: pointer;
    border-radius: 100vh;
}

.histContainer {
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 2em;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: 20px;
    padding-top: 1em;
    flex-grow: 1;
}

.history .profile {
    z-index: 199;
    margin-left: 24px;
}

.history .text.C {
    margin-left: 24px;
}

.card.history {
    padding-left: 0;
    /* padding-bottom: 24px; */
}

.notSignedIn {
    opacity: .45;
    font-style: italic;
}

.pen {
    position: absolute;
    top: .75em;
    right: .75em;
}

.pen:hover {
    opacity: 0.6;
}

.histContainer .hoverable .delete {
    position: absolute;
    right: 0;
    top: 0;
    opacity: .9;
    display: none;
}

.hoverable:hover .delete {
    display: block;
}

.delete .icon:hover {
    background-color: #58009F;
}

.dislikePrompt {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid hsla(0, 0%, 100%, .15);
    background: hsla(0, 0%, 100%, 0.021);
    padding-inline: 1em;
    padding-block: .9em;
    margin-top: .7em;
    border-radius: 14px;
    position: relative;
}

.tellUsMore {
    opacity: .4;
    font-weight: 700;
}

.dislikePrompt input {
    all: unset;
    margin-block: .6em;
    border: 1px solid hsla(0, 0%, 100%, .15);
    font-size: large;
    padding-block: .4em;
    padding-inline: .8em;
    border-radius: 10px;
    font-family: "Open Sans";
}

.submitDislike {
    background-color: #58009F;
    border: none;
    font-size: 15px;
    padding-block: 8px;
    color: whitesmoke;
    font-family: "Open Sans";
    border-radius: 8px;
    cursor: pointer;
    transition: all 200ms ease;
}

.submitDislike:hover {
    background-color: #47047d;
}

.close {
    all: unset;
    position: absolute;
    right: 1em;
}

.premiumTitle {
    display: flex;
    color: white;
    align-items: center;
    gap: .5em;
}

.premiumUl {
    font-family: 'Open Sans';
    color: rgb(173 173 173);
    margin-left: 1.3em;
    margin-top: .65em;
    display: flex;
    flex-direction: column;
    gap: .5em;
}

.basic {
    opacity: .7;
    /* font-weight: 100; */
}

.sub {
    font-size: x-large;
    font-family: "Open Sans";
    padding-block: .3em;
    color: white;
    background-color: #58009F;
    border: 2px solid #400074;
    box-sizing: border-box;
    border-radius: 8px;
}

.subscribe2 {
    border: none;
    width: max-content;
    font-size: small;
    font-weight: 500;
    color: white;
    opacity: .4;
    transition: all 200ms ease;
}

.subscribe2:hover {
    opacity: .8;
}

.AYS {
    opacity: 1;
}

.premiumUl a {
    all: unset;
    text-decoration: underline;
    cursor: pointer;
}

.emptyContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    gap: 0em;
}

/* Animation for pulsating glow */
@keyframes logoGlow {
    0% {
        filter: drop-shadow(0 0 1px rgba(255, 120, 242, 0.4));
    }

    50% {
        filter: drop-shadow(0 0 7px rgba(255, 120, 242, 0.7));
    }

    100% {
        filter: drop-shadow(0 0 1px rgba(255, 120, 242, 0.4));
    }
}

.pulsatingGlow {
    filter: drop-shadow(0 0 5px rgba(255, 120, 242, 0.6));
    /* Initial subtle glow */
    animation: logoGlow 6s infinite;
    /* Pulsating glow animation */
}

.suggestedQueries {
    display: flex;
    font-family: "Open Sans";
    color: white;
    gap: 1.2em;
    padding-inline: 1em;
    font-size: 14px;
}

.query {
    font-family: 'Open Sans';
    min-width: 150px;
    max-width: 160px;
    border: 1px solid gray;
    padding-inline: 12px;
    padding-block: 8px;
    border-radius: 10px;
    color: rgb(166, 172, 172);
    cursor: pointer;
    transition: all 200ms ease;
    font-size: 13px;
}

.query:hover {
    background-color: #242629;
}

.empty {
    height: 100%;
}

.analyticsBar {
    display: flex;
    justify-content: flex-end;
    margin-right: 1em
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation: fadeIn 0.2s ease-out;
    opacity: 1;
    transition: all 0.2s ease-out;
}

.modal-overlay.fadeOut {
    opacity: 0;
    backdrop-filter: blur(0px);
}

.modal-content {
    background: #151618;
    padding: 2rem;
    border-radius: 16px;
    max-width: 700px;
    width: 90%;
    position: relative;
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
    color: white;
    animation: slideUp 0.3s ease-out;
    transform: translateY(0);
    transition: all 0.2s ease-out;
}

.modal-content.slideDown {
    transform: translateY(30px);
    opacity: 0;
}

.modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
}

.modal-body h2 {
    font-family: "Open Sans", sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0;
    text-align: center;
    color: #fff;
}

.close-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: transparent;
    border: none;
    color: #ADADAD;
    cursor: pointer;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: all 0.2s ease;
}

.close-button:hover {
    background: rgba(255, 255, 255, 0.1);
    color: white;
}

.tier {
    text-decoration: none;
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: .4em;
    border: 1px solid #5800FF;
    padding-inline: .3em;
    border-radius: 8px;
    color: #dbc5fc;
    box-shadow: 0 0 10px blue
}

.dropdown-container {
    display: none;
}

.sendContainer.img {
    cursor: pointer;
}

.sendContainer.img svg {
    fill: #ADADAD;
    transition: all 200ms ease;
}

.sendContainer.img:hover svg {
    fill: white;
}

.imgWrapper {
    position: absolute;
    top: -3em;
    left: 1em;
    opacity: .6;
    transition: all 200ms ease;
}

.imgPreview {
    max-width: 56px;
    max-height: 56px;
    border-radius: 9px;
}

.imgLoading {
    position: relative;
}

.imgLoading::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 1rem;
}

.imgLoading::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin: -10px 0 0 -10px;
    border: 2px solid transparent;
    border-top-color: #ffffff;
    border-radius: 50%;
    animation: imgSpinner 1s linear infinite;
}

@keyframes imgSpinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.imgWrapper:hover {
    opacity: .87;
}

.closeImg {
    z-index: 99;
    color: white;
    fill: black;
    background-color: white;
    border-radius: 1000px;
    position: absolute;
    right: -4px;
    top: -5px;
    cursor: pointer;
}

.drag {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    background: rgba(10, 10, 10, 70%);
    z-index: 99999;
    color: white;
    font-family: Open Sans;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: .6rem;
}

.drag .title {
    font-size: larger;
    /* margin-bottom: .6rem; */
}

.drag .subtitle {
    font-size: small;
}

.msgImg {
    /* background-color: var(--purple); */
    margin-left: auto;
    display: block;
    padding: .6em;
    background-color: var(--purple);
    width: min-content;
    border-radius: 1rem;
    margin-bottom: .5rem;
}

.msgImg img {
    max-height: 150px;
    max-width: 150px;
    border-radius: .5rem;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        backdrop-filter: blur(0px);
    }

    to {
        opacity: 1;
        backdrop-filter: blur(5px);
    }
}

@keyframes slideUp {
    from {
        transform: translateY(30px);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@media (max-width: 480px) {
    .modal-content {
        width: 95%;
        padding: 1.5rem;
    }

    .modal-body h2 {
        font-size: 1.2rem;
    }
}

/**
!!!
*/

.menuButton {
    all: unset;
    font-family: "Open Sans";
    font-size: 18px;
}

.menu {
    display: flex;
    flex-direction: column;
    gap: 2em;
    padding-top: 1.5em;
}

.queries {
    display: flex;
    flex-direction: column;
    gap: .6em;
    padding-left: 1em;
}

.queries li {
    all: unset;
    font-size: large;
}

/**
!!!
*/

@media(max-width: 1470px) {
    .rightBar {
        width: 370px;
    }
}

@media(max-width: 1440px) {
    .message {
        max-width: 500px;
    }
}

@media(max-width: 1300px) {
    .message {
        max-width: 450px;
    }
}

@media(max-width:1200px) {
    .back {
        display: flex;
    }

    .body:has(.middleBar.x) {
        padding-right: 11px;
    }

    .bars1 {
        display: block;
    }

    .rightBar.closed {
        display: none;
    }

    .rightBar {
        width: 100%;
    }

    .leftBar.closedOff {
        /* width: 100%; */
    }

    .middleBar.closedOff {
        display: none;
    }
}

@media(max-width:1017px) {
    /* .freeStuff {
        display: none;
    } */
}

@media(max-width: 764px) {

    .collapse-icon {
        display: none;
    }

    .leftBar {
        display: none;
    }

    .fu {
        display: flex;
    }

    .back.back2 {
        display: flex;
    }

    .middleBar .desc .bio {
        font-size: 9.8px;
    }

    .middleBar .desc .name {
        font-size: 14px;
    }

    .middleBar .desc {
        gap: 0
    }

    .top .profile img {
        width: 50px;
    }

    .middleBar .profile {
        gap: .5em;
    }

    .mini {
        display: flex;
        gap: 1em;
        align-items: center;
    }

    .notShown {
        display: none;
    }

    .leftBar.shown {
        display: flex;
        width: 100%;
    }

    .rightBar.shown {
        display: block;
    }

    .history .hoverable:hover,
    .history .selected {
        width: 90%;
    }

    .middleBar.closedOff.shown {
        display: block;
    }

    .bars2 {
        display: block;
    }

    .analyticsBar {
        justify-content: space-between;
    }
}

@media(max-width:699px) {
    .pricing-container .header {
        margin-inline: -1em;
    }
}

@media(max-width:553px) {
    .word {
        display: none;
    }
}

@media(max-width:401px) {
    .pricing-container .header {
        margin-inline: -.5em;
    }
}

@media(max-width: 935px) {
    .welcome {
        display: none;
    }
}

@media(max-width:517px) {
    .message {
        max-width: 90%;
    }
}

@media(max-height:844px) {
    .bottom {
        gap: .5em;
    }

    .desc2 {
        max-height: calc(100svh - (150px + 23px + 27.5px + 1em + 2em + 47px + 1.5em + 21.5px + 390px));
    }
}

@media(max-width:477px) {

    .input,
    .sendContainer {
        height: 50px;
        font-size: 12px;
    }

    .send {
        padding-block: .7em;
        padding-inline: 1.2em;
        font-size: 12px;
    }
}

@media(max-width: 643px) {

    /* .subscribe,
    .tier {
        display: none;
    } */
    .logo {
        font-size: 20px;
    }

    .imgLogo {
        height: 30px;
    }

    .subscribe {
        font-size: 13px;
        padding-block: 0;
        height: 35px;
    }

    img.user {
        height: 35px;
        width: 35px;
    }

    .socials {
        height: 35px;
    }

}

@media(max-width:514px) {
    .dropdown-container {
        display: block;
    }

    .header .subscribe {
        display: none !important;
    }

    .subscribe.Premium {
        display: block !important;
    }
}

@media(max-width:414px) {
    .logo {
        font-size: 20px;
    }

    .imgLogo {
        height: 21.5px;
    }

    .logo {
        margin-left: 10px;
    }

    .subscribe {
        font-size: 12px;
        padding-block: 0;
        height: 30px;
    }

    img.user {
        height: 30px;
        width: 30px;
    }

    .socials {
        height: 30px;
        gap: .2em;
    }
}

@media(max-height:813px) {
    .picContainer img {
        height: 370px;
        width: 290px;
    }

    .o {
        height: 380px;
        width: 290px;
    }

    .desc2 {
        max-height: calc(100svh - (150px + 23px + 27.5px + 1em + 2em + 47px + 1.5em + 21.5px + 355px));
    }

    .picContainer img {
        height: 370px;
        width: 290px;
    }

    .o {
        height: 380px;
        width: 290px;
    }
}

@media(max-height:771px) {
    .desc.desc2 {
        line-height: 14px;
    }
}

@media(max-height:746px) {
    .card .text.meet {
        font-size: 15px;
    }

    .links .icon,
    .meet .back {
        height: 30px;
        width: 30px;
    }

    .meet .back img {
        height: 12px;
    }

    .links img,
    .links svg {
        height: 16px;
    }

    .desc2 {
        max-height: calc(100svh - (150px + 23px + 27.5px + 1em + 2em + 47px + 1.5em + 21.5px + 325px));
    }
}

@media(max-height:746px) {
    .picContainer img {
        height: 370px;
        width: 290px;
    }

    .o {
        height: 380px;
        width: 290px;
    }
}

@media(max-height:716px) {
    .picContainer img {
        height: 300px;
        width: 235px;
    }

    .o {
        height: 310px;
        width: 235px;
    }

    .nameTitle {
        position: absolute;
        bottom: .4em;
        font-size: 40px;
        font-weight: 500;
        font-family: "Oswald";
        color: #FFF;
    }

    .nameDesc {
        position: absolute;
        bottom: 0;
        font-size: 13px;
        font-family: "Open Sans";
        color: #ADADAD;
    }

    .desc2 {
        max-height: calc(100svh - (150px + 23px + 27.5px + 1em + 2em + 47px + 1.5em + 21.5px + 255px));
    }

    .card.models {
        height: 310px
    }

    .fu {
        top: .5em;
        right: .5em;
    }

    .history {
        gap: 0;
        padding-bottom: 0;
    }

}

@media (max-width: 382px) {
    .mini {
        gap: .5em;
    }

    .mini .profile .desc {
        padding-right: 1em;
    }
}

@media (max-width: 495px) {
    .emptyContainer img {
        /* height: 200px; */
        width: 180px !important;
    }
}

@media (max-width: 520px) {
    .suggestedQueries {
        font-size: 11px;
        gap: .7em;
    }

    .query {
        padding-inline: 8px;
    }

    .username {
        display: none;
    }

    .tier {
        position: absolute;
        top: -.975em;
        right: -.7em;
        transform: rotate(30deg);
        border: none;
        box-shadow: none;
    }

    img.user.Premium {
        border: 1px solid #5800FF;
        box-shadow: 0 0 10px blue;
    }
}

.modal-icon {
    color: #58009F;
    margin-bottom: 0.5rem;
    animation: bounceIn 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.modal-description {
    text-align: center;
    color: #ADADAD;
    font-size: 1rem;
    line-height: 1.5;
    margin: 0;
    max-width: 400px;
}

.modal-button {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    background-color: #58009F;
    color: white;
    border: none;
    padding: 0.875rem 1.5rem;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
    font-family: "Open Sans", sans-serif;
}

.modal-button:hover {
    background-color: #6b00bf;
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(88, 0, 159, 0.3);
}

.modal-button:active {
    transform: translateY(0);
}

.google-icon {
    color: white;
}

@keyframes bounceIn {
    0% {
        opacity: 0;
        transform: scale(0.3);
    }

    50% {
        opacity: 0.9;
        transform: scale(1.1);
    }

    80% {
        opacity: 1;
        transform: scale(0.89);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

/* Enhance existing animations */
@keyframes fadeIn {
    from {
        opacity: 0;
        backdrop-filter: blur(0);
    }

    to {
        opacity: 1;
        backdrop-filter: blur(5px);
    }
}

@keyframes slideUp {
    from {
        transform: translateY(30px);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.leftBar {
    width: 300px;
    min-width: 300px;
    /* or your desired width */
    transition: width 0.3s ease;
    overflow: hidden;
}

.leftBar.collapsed {
    /* or your desired collapsed width */
}

.error .reason {
    font-weight: 700;
    margin-left: 3rem;
    padding-top: .5rem;
    color: rgb(203, 1, 1);
    text-shadow: #1e0303 0px 0px 10px;
}

/* Adjust card content when collapsed */
.leftBar.collapsed .card {
    padding: 0.5rem;
}

.leftBar.collapsed .profile .desc,
.leftBar.collapsed .newAlpha span,
.leftBar.collapsed .text:not(.icon) {
    display: none;
}

.leftBar.collapsed .profile {
    justify-content: center;
    padding: 0.5rem;
}

.leftBar.collapsed .profile img {
    margin: 0;
}

.collapse-icon {
    cursor: pointer;
    transition: transform 0.3s ease;
}

.middleBar .top .icon:hover,
.collapse-icon:hover {
    opacity: 0.6;
}

.input:focus {
    box-shadow: 0px 0px 20px #58009F;
}

.a2 {
    padding-inline: 14%;
}

.haveCode {
    all: unset;
    position: absolute;
    font-size: .7rem;
    top: 3.5em;
    right: 1.5em;
    opacity: .4;
    text-decoration: underline;
    cursor: pointer;
}

@media(max-width:715px) {
    .a2 {
        padding-inline: 10%;
    }
}

@media(max-width:647px) {
    .a2 {
        padding-inline: 7%;
    }
}

@media(max-width:612px) {
    .a2 {
        padding-inline: 3%;
    }
}

@media(max-width:510px) {
    .w1 {
        flex-direction: column;
    }
}

@keyframes pulse {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

/* Adjust the middle content to take up remaining space */
.middleBar {
    flex: 1;
    transition: margin-left 0.3s ease;
}

/* Optional: adjust spacing when sidebar is collapsed */
.leftBar.collapsed+.middleBar {
    margin-left: 80px;
    /* same as collapsed width */
}

.expand-button {
    position: fixed;
    left: 20px;
    top: 100px;
    /* Adjust based on your header height */
    background: #151618;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease;
    z-index: 100;
}

.expand-button:hover {
    background: #1f2023;
    transform: scale(1.05);
}

.leftBar,
.rightBar {
    transition: transform 0.3s ease, width 380ms ease, min-width 380ms ease;
}


.leftBar.collapse {
    min-width: 0;
    width: 0;
}

.social-links {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
}

.social-link {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    border-radius: 8px;
    background-color: #f5f5f5;
    text-decoration: none;
    color: inherit;
    transition: background-color 0.2s;
}

.social-link:hover {
    background-color: #e5e5e5;
}

.social-link svg {
    width: 24px;
    height: 24px;
}

.social-link {
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
    width: 100%;
}

.dropdownItem {
    align-items: center;
    padding-left: .4em;
    padding-top: .2em;
    border-radius: 5px;
    gap: .8em;
}

.dropdownItem:hover {
    /* outline:1px solid ; */
    background-color: rgba(87, 87, 87, 0.317);
}

.social-link.instagram {
    background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
}

.social-link.twitter {
    background: linear-gradient(45deg, #1DA1F2 0%, #19608F 100%);
}

.social-link.tiktok {
    background: linear-gradient(45deg, #000000 0%, #3f1c7a 100%);
}

.social-link:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.spinner {
    display: inline-flex;
    gap: 4px;
}

.spinner>div {
    width: 8px;
    height: 8px;
    background-color: currentColor;
    border-radius: 100%;
    display: inline-block;
    animation: bounce 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
    animation-delay: -0.32s;
}

.spinner .bounce2 {
    animation-delay: -0.16s;
}

@keyframes bounce {

    0%,
    80%,
    100% {
        transform: scale(0);
    }

    40% {
        transform: scale(1.0);
    }
}