.main {
    min-height: calc(100vh - 4rem);
    background-color: var(--black);
    padding: 4rem 0;

}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
}

.content {
    background-color: rgb(14, 13, 14);
    border-radius: 2rem;
    padding: 2rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
}

.header {
    margin-bottom: 1.75rem;
}

.icon {
    color: var(--purple);
    margin-bottom: 1.5rem;
}

.title {
    color: white;
    font-size: 1.5rem;
    margin-bottom: .5rem;
}

.subtitle {
    color: var(--light-gray);
    font-size: 1rem;
    max-width: 500px;
    margin: 0 auto;
}

.features {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 2rem;
    margin-bottom: 1.4rem;
    width: 100%;
}

.feature {
    padding: 1.5rem;
    background-color: var(--gray);
    border-radius: 0.75rem;
}

.feature h3 {
    color: white;
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
}

.feature p {
    color: var(--light-gray);
    font-size: 0.875rem;
    line-height: 1.5;
}

.joinButton {
    background-color: var(--purple);
    color: white;
    border: none;
    padding: 1rem 2rem;
    border-radius: 0.5rem;
    font-size: 1.125rem;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 3rem;
    transition: opacity 0.2s;
}

.joinButton:hover {
    opacity: 0.9;
}

.stats {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    width: 100%;
    border-top: 1px solid var(--light-gray);
    padding-top: 2rem;
}

.stat {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.statValue {
    color: white;
    font-size: 1.5rem;
    font-weight: 600;
}

.statLabel {
    color: var(--light-gray);
    font-size: 0.875rem;
}

.features2 h3 {
    margin: 0
}

.features2 .feature {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .5rem;
    transition-timing-function: cubic-bezier(0.2, 0, 0.2, 1);
    transition-property: all;
    transition-duration: 150ms;
    box-sizing: border-box;
    border: 1px solid transparent;
}

.features2 .feature:hover {
    border: 1px solid rgb(168 85 247 / 0.3);
    box-sizing: border-box;
    /* box-shadow: 0 0 10px #C084FC; */
}

.features2 .feature p {
    font-size: .8rem;
}