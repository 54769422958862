.pricing-container {
    min-height: 100vh;
    background-color: #0a0a0a;
    color: white;
    padding: 0rem 2rem;
    padding-bottom: 2rem;
}

.pricing-header {
    text-align: center;
    margin-bottom: 2rem;
    padding-top: 1rem;
}

.pricing-title {
    color: #ffffff;
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.pricing-subtitle {
    color: #888;
    font-size: 1.2rem;
}

.pricing-cards {
    display: flex;
    justify-content: center;
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.pricing-card {
    background-color: #151618;
    border-radius: 16px;
    padding: 2rem;
    width: 350px;
    position: relative;
    transition: transform 0.3s ease;
}

.pricing-card:hover {
    transform: translateY(-10px);
}

.pricing-card.premium {
    border: 2px solid #58009F;
}

.current-plan {
    position: absolute;
    top: -12px;
    right: -12px;
    background-color: #58009F;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    font-size: 0.9rem;
}

.plan-name {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.price-container {
    margin: 1.5rem 0;
}

.price {
    font-size: 2.5rem;
    font-weight: bold;
}

.original-price {
    position: relative;
    /* Needed for the pseudo-element */
    color: #888;
    /* Text color */
    margin-right: 1rem;
}

.original-price::after {
    content: '';
    /* No visible content */
    position: absolute;
    /* Allows precise positioning */
    left: 0;
    right: 0;
    /* Spans the full width of the text */
    top: 50%;
    /* Position at the middle of the text */
    height: 2px;
    /* Thickness of the line */
    background-color: #b31e1e;
    /* Color of the strikethrough */
    pointer-events: none;
    /* Ensures the pseudo-element doesn't interfere with clicks */
}

.period {
    color: #888;
    font-size: 1rem;
}

.features {
    margin: 2rem 0;
}

.feature {
    display: flex;
    align-items: center;
    margin: 1rem 0;
    color: #888;
}

.feature svg {
    margin-right: 1rem;
    color: #58009F;
}

.subscribe-button {
    width: 100%;
    padding: 1rem;
    border-radius: 8px;
    border: none;
    font-weight: bold;
    cursor: pointer;
    transition: opacity 0.3s ease;
}

.subscribe-button:hover {
    opacity: 0.9;
}

.subscribe-button.primary {
    background-color: #58009F;
    color: white;
}

.subscribe-button.secondary {
    background-color: #1f2026;
    color: white;
}

.subscribe-button:disabled {
    opacity: .7;
    cursor: not-allowed;
}

.annual-savings {
    margin-top: 1rem;
    text-align: center;
    color: #58009F;
    font-size: 0.9rem;
}

@media(max-width: 577px) {
    .pricing-cards {
        flex-direction: column;
        align-items: center;
    }
}

@media(max-width: 400px) {
    .pricing-container {
        padding-inline: 1rem;
    }
}

.upgrade-modal {
    max-width: 500px;
}

.upgrade-details {
    margin: 1.5rem 0;
}

.upgrade-details p {
    color: #888;
    margin-bottom: 1rem;
}

.upgrade-details ul {
    list-style: none;
    padding: 0;
}

.upgrade-details li {
    color: #fff;
    margin: 0.75rem 0;
    padding-left: 1.5rem;
    position: relative;
}

.upgrade-details li:before {
    content: "•";
    color: #58009F;
    position: absolute;
    left: 0;
}