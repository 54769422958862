.modalOverlay {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(4px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5000;
    animation: fadeIn 200ms ease-out;
}

.modalContent {
    background-color: #1A1A1A;
    border-radius: 0.75rem;
    width: 100%;
    max-width: 28rem;
    padding: 1.5rem;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1);
    animation: slideUp 200ms ease-out;
}

.modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
}

.title {
    font-size: 1.5rem;
    font-weight: bold;
    color: white;
}

.closeButton {
    all: unset;
    color: #9CA3AF;
    cursor: pointer;
}

.closeButton:hover {
    color: white;
}

.section {
    margin-top: 1rem;
}

.card {
    background-color: #252525;
    padding: 1rem;
    border-radius: 0.5rem;
    border: 1px solid #333333;
    margin-bottom: 1rem;
    position: relative;
    overflow: hidden;
}

.completedOverlay {
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 0.5rem;
    backdrop-filter: blur(2px);
}

.completedText {
    color: #22C55E;
    font-weight: 600;
    font-size: 1.125rem;
}

.completedSubtext {
    text-align: center;
    color: #9CA3AF;
    font-size: 0.875rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.cardTitle {
    color: white;
    font-weight: 700;
    margin-bottom: 0.5rem;
    font-size: 1rem;
}

.cardDescription {
    color: #9CA3AF;
    font-size: 0.86rem;
    margin-bottom: 0.75rem;
    font-weight: 400;
}

.socialButtons {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}

.button {
    border: none;
    padding: 0.5rem 1rem;
    padding-block: .6em;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    width: 100%;
    color: white;
    transition: all 300ms;
    font-size: 1rem;
    cursor: pointer;
}

.button:disabled {
    cursor: default;
}

.button:not(:disabled):hover {
    opacity: 0.9;
}

.instagramButton {
    background: linear-gradient(to right top, #9333EA, #DB2777, #EAB308);
}

.tiktokButton {
    background: linear-gradient(45deg, #000 0%, #3f1c7a 100%);
}

.xButton {
    background-color: #000000;
}

.completedButton {
    background-color: #16A34A;
}

.loadingSpinner {
    width: 1.25rem;
    height: 1.25rem;
    border: 2px solid white;
    border-top-color: transparent;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

.input {
    width: 100%;
    background-color: #1A1A1A;
    border: 1px solid #333333;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    color: white;
    font-size: 1rem;
}

.input:focus {
    outline: none;
    border-color: #7C3AED;
}

.input:disabled {
    opacity: .7;
}

.submitButton {
    border: none;
    width: 100%;
    background-color: #7C3AED;
    color: white;
    padding: 0.5rem;
    border-radius: 0.5rem;
    font-size: 1rem;
    padding-block: .6em;
    cursor: pointer;
}

.submitButton:hover {
    background-color: rgba(124, 58, 237, 0.9);
}

.submitButton:disabled {
    opacity: .7;
    cursor: not-allowed;
}

.successMessage {
    color: #22C55E;
    font-size: 0.875rem;
    margin-top: 0.5rem;
}

.referralCode {
    background-color: #1A1A1A;
    border: 1px solid #333333;
    border-radius: 0.5rem;
    padding: 0.75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.code {
    font-size: 0.875rem;
    color: #7C3AED;
}

.copyButton {
    all: unset;
    color: #7C3AED;
    font-size: 0.875rem;
    font-weight: 400;
    cursor: pointer;
}

.copyButton:hover {
    color: rgba(124, 58, 237, 0.8);
}

.card {
    font-family: "Open Sans";
}

.x {
    all: unset;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes slideUp {
    from {
        opacity: 0;
        transform: translateY(10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@media(max-width:478px) {
    .modalContent {
        margin-inline: .5em;
    }
}

.reviewContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.stars {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
}

.star {
    color: #9CA3AF;
    opacity: 0.5;
    transition: all 300ms;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
}

.star:hover:not(:disabled),
.starFilled {
    opacity: 1;
    color: #EAB308;
}

.pendingText {
    color: #9CA3AF;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.875rem;
}

.submitButton {
    width: 100%;
    background-color: #7C3AED;
    color: white;
    padding: 0.5rem;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    transition: all 300ms;
    padding-block: .7rem;
}

.submitButton:hover:not(:disabled) {
    background-color: rgba(124, 58, 237, 0.9);
}

.submitButton:disabled {
    cursor: default;
    opacity: 0.7;
}

.submitButtonDisabled {
    background-color: #4B5563;
    cursor: default;
}


.reviewModalOverlay {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
}

.reviewModalContent {
    background-color: #1A1A1A;
    border-radius: 0.75rem;
    width: 100%;
    max-width: 24rem;
    padding: 1.5rem;
    font-family: "Open Sans";
}

.reviewModalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
}

.reviewModalTitle {
    color: white;
    font-size: 1.25rem;
    font-weight: 600;
}

.reviewModalBody {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.reviewTextarea {
    width: 100%;
    min-height: 8rem;
    background-color: #252525;
    border: 1px solid #333333;
    border-radius: 0.5rem;
    padding: 0.75rem;
    color: white;
    resize: vertical;
    font-family: "Open Sans";
}

.reviewTextarea:focus {
    outline: none;
    border-color: #7C3AED;
}