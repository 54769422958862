.countdown-banner {
    background: linear-gradient(to right, #dc2626, #ef4444);
    color: white;
    padding: 12px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin-inline: -2rem;
}

.countdown-text {
    font-weight: 600;
}

.countdown-timer {
    display: flex;
    align-items: center;
    gap: 4px;
    font-family: monospace;
}

.time-block {
    background-color: rgba(0, 0, 0, 0.2);
    padding: 4px 8px;
    border-radius: 4px;
}

.time-separator {
    font-weight: bold;
}

@media(max-width: 401px) {
    .countdown-banner{
        margin-inline: -1em;
    }
}