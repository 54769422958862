.card {
    background-color: var(--black);
    border-radius: 0.75rem;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-family: 'Open Sans';
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.titleGroup {
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.iconWrapper {
    background-color: rgba(88, 0, 159, 0.2);
    padding: 0.5rem;
    border-radius: 0.5rem;
}

.icon {
    color: var(--purple);
}

.title {
    color: var(--light-gray);
    font-size: 0.875rem;
}

.change {
    font-size: 0.875rem;
}

.positive {
    color: #34D399;
}

.negative {
    color: #EF4444;
}

.value {
    font-size: 1.5rem;
    font-weight: 600;
    color: white;
}