.subscription-details {
    margin-top: 1.5rem;
    padding-top: 1.5rem;
    border-top: 1px solid #1f2026;
}

.renewal-info {
    margin-bottom: 1.5rem;
}

.renewal-date {
    color: white;
    font-weight: 500;
    margin-bottom: 0.5rem;
}

.billing-info {
    color: #888;
    font-size: 0.9rem;
}

.unsubscribe-button {
    width: 100%;
    padding: 1rem;
    background-color: transparent;
    border: 1px solid #dc2626;
    color: #dc2626;
    border-radius: 8px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
}

.unsubscribe-button:hover {
    background-color: #dc2626;
    color: white;
}