.container {
    background-color: var(--black);
    border-radius: 0.75rem;
    padding: 1.5rem;
}

.header {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin-bottom: 1.5rem;
}

.icon {
    color: var(--purple);
}

.title {
    color: white;
    font-size: 1.25rem;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.linkBox {
    display: flex;
    gap: 0.5rem;
    background-color: rgba(83, 83, 83, 0.1);
    padding: 0.5rem;
    border-radius: 0.5rem;
}

.linkInput {
    flex: 1;
    background: none;
    border: none;
    color: white;
    font-size: 0.875rem;
    padding: 0.5rem;
    outline: none;
}

.copyButton,
.visitButton {
    background: none;
    border: none;
    color: var(--light-gray);
    cursor: pointer;
    padding: 0.5rem;
    border-radius: 0.25rem;
    transition: all 0.2s;
}

.copyButton:hover,
.visitButton:hover {
    color: white;
    background-color: rgba(83, 83, 83, 0.2);
}

.stats {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 1rem;
}

.stat {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.statLabel {
    color: var(--light-gray);
    font-size: 0.875rem;
}

.statValue {
    color: white;
    font-size: 1.25rem;
    font-weight: 500;
}