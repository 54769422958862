.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
}

.main {
    padding: 2rem 0;
}

.header {
    margin-bottom: 2rem;
}

.title {
    font-size: 1.5rem;
    color: white;
    margin-bottom: 0.25rem;
}

.subtitle {
    color: var(--light-gray);
    font-size: 0.875rem;
}

.statsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
    margin-bottom: 2rem;
}

.section {
    margin-bottom: 2rem;
}